<template>
  <CModal
    v-if="detail"
    ref="mainModal"
    :title="modalTitle"
    :class="{ 'is-loading': detailsLoading }"
    :show="value"
    size="xl"
    centered
    @update:show="(value) => this.$emit('input', value !== false)"
  >
    <template #default>
      <CRow v-if="detail.scenario">
        <CCol>
          <CRow>
            <CCol>
              <dl>
                <dt>Legal Entity</dt>
                <dd>{{ detail.scenario.legalEntityName }}</dd>
              </dl>
            </CCol>
            <CCol>
              <dl>
                <dt>Transaction Type</dt>
                <dd>{{ transactionType }}</dd>
              </dl>
            </CCol>
            <CCol>
              <dl>
                <dt>Tax Scenario Type</dt>
                <dd>{{ detail.scenario.taxScenarioTypeDescription }}</dd>
              </dl>
            </CCol>
            <CCol>
              <dl>
                <dt>Expected Tax Outcome</dt>
                <dd>{{ detail.scenario.taxOutcomeName }}</dd>
              </dl>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <dl>
                <dt>Expected Tax Result</dt>
                <dd>{{ detail.scenario.expectedTaxability }}</dd>
              </dl>
            </CCol>
            <CCol>
              <dl>
                <dt>Ship From Location</dt>
                <dd>{{ detail.scenario.sfLoc }}</dd>
              </dl>
            </CCol>
            <CCol>
              <dl>
                <dt>Ship To Location</dt>
                <dd>{{ detail.scenario.stLoc }}</dd>
              </dl>
            </CCol>
            <CCol>
              <dl>
                <dt>Expected Taxing Jurisdiction</dt>
                <dd>
                  {{ detail.scenario.taxingJurisdiction.jurisdictionCode }}
                </dd>
              </dl>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <dl>
                <dt>Tax Scenario Number</dt>
                <dd>{{ detail.scenario.key }}</dd>
              </dl>
            </CCol>
            <CCol
              ><dl>
                <dt>Tax Scenario Model</dt>
                <dd>{{ modelName }}</dd>
              </dl></CCol
            >
            <CCol></CCol>
            <CCol></CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow v-else>
        <CCol>
          <h3>No Scenario Data</h3>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CTextarea
            class="xml-review"
            :value="detail.sourceXml"
            rows="15"
            readonly
          >
            <template #label>
              <CRow>
                <CCol class="flex-bottom">
                  <label>Input XML</label>
                </CCol>
                <CCol class="text-right">
                  <transition name="fade">
                    <span v-if="inputText" class="mr-2" style="color: green"
                      ><strong>Copied!</strong></span
                    >
                  </transition>
                  <CButton
                    class="mb-2"
                    color="primary"
                    size="sm"
                    @click="copyText(detail.sourceXml, 'inputText')"
                    >Copy</CButton
                  >
                </CCol>
              </CRow>
            </template>
          </CTextarea>
        </CCol>
        <CCol>
          <CTextarea
            class="xml-review"
            :value="detail.responseXml"
            rows="15"
            readonly
          >
            <template #label>
              <CRow>
                <CCol class="flex-bottom">
                  <label>Output XML</label>
                </CCol>
                <CCol class="text-right">
                  <transition name="fade">
                    <span v-if="outputText" class="mr-2" style="color: green"
                      ><strong>Copied!</strong></span
                    >
                  </transition>
                  <CButton
                    class="mb-2"
                    color="primary"
                    size="sm"
                    @click="copyText(detail.responseXml, 'outputText')"
                    >Copy</CButton
                  >
                </CCol>
              </CRow>
            </template>
          </CTextarea>
        </CCol>
      </CRow>
    </template>
    <template #footer>
      <CButton
        class="btn btn-primary"
        @click="$refs.mainModal.$emit('update:show', false, $event)"
        >Close</CButton
      >
    </template>
  </CModal>
</template>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.xml-review {
  textarea.form-control {
    &:disabled,
    &:read-only {
      color: #000;
      background-color: #f2f4f7;
      font-family: monospace;
    }
  }
}
.flex-bottom {
  flex-direction: row;
  align-items: flex-end;
  display: flex;
}
</style>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    testResult: {
      type: [String, Number],
      required: false,
    },
    engine: {
      type: String,
      required: false,
      default: 'VTX',
    },
  },
  data() {
    return {
      detailsLoading: false,
      detail: null,
      inputText: false,
      outputText: false,
    };
  },
  computed: {
    modalTitle() {
      let t = 'Test Result Detail';
      if (this.engine === 'VTX' && this.detail && this.detail.source) {
        return `${t} - ${this.detail.source.vertexDocument.documentNumber}`;
      }
      return t;
    },
    transactionType() {
      switch (this.engine) {
        case 'SBX':
          switch (this.detail.result.companyRole) {
            case 'SELLER':
              return 'Sale';
            case 'BUYER':
              return 'Purchase';
            case 'MIDDLEMAN':
              return 'Middleman';
            default:
              return 'N/A';
          }
        case 'VTX':
          return this.detail.result.documentTransactionType;
        default:
          return 'N/A';
      }
    },
    modelName() {
      if (
        this.detail &&
        this.detail.source &&
        this.detail.source.transactionProperties
      ) {
        return (
          this.detail.source.transactionProperties['modelName'] ||
          this.detail.source.transactionProperties['_modelName'] ||
          'N/A'
        );
      } else {
        return 'N/A';
      }
    },
  },
  watch: {
    testResult(val) {
      this.detailsLoading = true;
      this.$store
        .dispatch('tests/fetchTestResultDetails', {
          value: val,
          type: this.engine,
        })
        .then((response) => {
          this.detail = response;
        })
        .finally(() => (this.detailsLoading = false));
    },
  },
  methods: {
    getTestResultDetail(resultId) {},
    copyText(t, showVal) {
      this.$copyText(t).then(() => {
        this[showVal] = true;
        setTimeout(() => {
          this[showVal] = false;
        }, 700);
      });
    },
  },
};
</script>
